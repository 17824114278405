import { useState } from 'react';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import logo from './logo.svg';
import './App.css';

function App() {
  const [mintAmount, setMintAmount] = useState(5);
  const nftCost = 1.0;
  const clientId = '0d013110-8908-4d90-bccf-8920d0dd16b0';

  const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  }

  const handleIncrement = () => {
    setMintAmount(mintAmount + 1);
  }

  return (
    <div>
      <img src="https://bafybeibw4ghe3ir47m4grey6svi3hiwhush4lbvszojswq5pllfq7gnaqq.ipfs.dweb.link/newseanies600.gif" width="100"/><br/>

      How many Seanies do you want to buy? They're $1 each. Get like 20.<br/>
      <button onClick={handleDecrement}> - </button>
      <input 
        readOnly
        type="number" 
        value={mintAmount} 
      />
      <button onClick={handleIncrement}> + </button>

      <CrossmintPayButton
        clientId={clientId}
        environment="production"
        mintConfig={{
          type: "thirdweb-drop",
          totalPrice: (nftCost * mintAmount).toString(), 
          quantity: mintAmount, // the `_quantity` property should match what is in your mint function
          paymentMethod:"MATIC"

          
          // your custom minting arguments...
        }}
      />
      <br/>
      Yes this is the real site. I know it looks like shit, I can't design things!
    </div>
  );
}

export default App;
